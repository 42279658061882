/* .qr-reader {
    width: 430px;
    height: 100vh;
    margin: 0 auto;
    position: relative;
}

.qr-reader video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
}

.qr-reader .qr-box {
    width: 100% !important;
    left: 0 !important;
}

.qr-reader .qr-frame {
    position: absolute;
    fill: none;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

@media (max-width: 426px) {
    .qr-reader {
        width: 100%;
    }
} */

/* html,
body {
    height: 100%;
    overflow: hidden;
} */

.qr-reader {
    width: 100vw;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.qr-reader video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.qr-reader .qr-box {
    width: 100% !important;
    left: 0 !important;
}

.qr-reader .qr-frame {
    position: absolute;
    fill: none;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 426px) {
    .qr-reader {
        width: 100%;
    }
}